// Code
import { useState } from 'react';
import Button from 'react-bootstrap/Button';

// Assets
import ritual_1000 from './ritual_1000.webp';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function range(size, startAt = 0) {
  return [...Array(size).keys()].map(i => i + startAt);
}

function BingoButton({value, variant, checked, handler}) {
  let realVariant = variant
  if (!checked) {
    realVariant = `outline-${variant}`
  }
  return (
    <Button className="btn-bingo" variant={realVariant} onClick={handler}>
      {value}
    </Button>
  );
}

function App() {
  const [boardValues, setBoardValues] = useState(Array(75).fill(false));

  function handleClick(i)  {
    setBoardValues(boardValues.map((val, idx) => {
      if (idx + 1 === i) {
        return !val;
      } else {
        return val;
      }
    }));
  } 
  const letterStarts = {
    "B": 1,
    "I": 16,
    "N": 31,
    "G": 46,
    "O": 61
  };
  const letterVariants = {
    "B": "primary",
    "I": "warning",
    "N": "success",
    "G": "danger",
    "O": "info"
  }

  // letterStarts.forEach((letter) => {
  //   rows.push(<td>{letter}</td> + range(15, letterStarts[letter]).map((num) => {<BingoButton key={letter + num} value={num}/>}))
  // })
  return (
    <div className="App">
      <header className="App-header">
        <img src={ritual_1000} className="App-logo" alt="logo" />
        <h1 id="ritual-title">Ritual Kava Lounge</h1>
      </header>
      <section className="bingo-main">
        <table className="bingo-table">
          <tbody>
            {
              Object.keys(letterStarts).map((letter) =>{
                let numbers = range(15, letterStarts[letter]).map((num) => 
                  <td key={letter + num}><BingoButton value={num} variant={letterVariants[letter]} checked={boardValues[num - 1]} handler={() => handleClick(num)}/></td>
                  )
                return <tr key={letter}>
                  <td><BingoButton value={letter} variant={letterVariants[letter]} checked={true}/></td>
                  {numbers}
                </tr>;
              })
            }
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default App;
